import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { deviceList } from "../api/charts";
import { filterChildEquipment } from "../api/charts";
import { voiceToWord } from "../api/charts";
import { voiceToChart } from "../api/charts";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "foot",
  props: ["getearchData", "gltf", "scene", "chooseFromMapArr", "chooseFromMapIdArr"],
  data() {
    return {
      toggleValue: 0,
      // 主题颜色初始值为 0浅色 1深色
      mapBtn: false,
      searchData: "",
      //对比图+设备信息 选择结果
      equipmentList: {
        radio: "燃气锅炉",
        children: [],
        params: "",
        tune: [],
        type: "",
        chartTimeRange: [],
        checkAll: false,
        childrenId: [],
        paramsCode: ""
      },
      isIndeterminate: false,
      tuneList: [{
        tuneSwitch: true,
        name: "管道1"
      }, {
        tuneSwitch: true,
        name: "管道2"
      }, {
        tuneSwitch: true,
        name: "管道3"
      }],
      equipment: [],
      filterParams: [],
      seriesArr: [],
      isDisabled: false,
      selectChildName: "",
      form: {
        params: "",
        type: "",
        value: ""
      },
      tuneIsOpen: true,
      tuneIsOpenText: "关闭",
      colorClone: {},
      searchPopoverIsVisible: false,
      getChooseFromMapArr: [],
      getChooseFromMapIdArr: [],
      selectFlag: false,
      selectArr: [],
      checkBoxMax: 1000,
      flowSrc: null,
      microphoneTimer: null,
      oringColor: {},
      jsonData: '[{"name":"John","age":30},{"name":"Jane","age":25},{"name":"Jim","age":35}]',
      isBlinking: false,
      blinkInterval: null,
      normalColor: "#59baf2 !important",
      // 初始颜色
      isHiddenAlarm: 0
    };
  },
  mounted() {
    this.getdeviceList();
    this.getChooseFromMapArr = this.chooseFromMapArr;
    this.getChooseFromMapIdArr = this.chooseFromMapIdArr;
    //将讯飞的html嵌入进来
    this.flowSrc = window.origin + "/VoiceDictation-master/index.html";

    //闪烁报警
    this.checkAndSetOringColorGFBAndOther();
  },
  directives: {
    popover: {
      bind(el, binding, vnode, oldVnode) {
        // 1、在bind钩子函数中获取弹窗标题栏元素(dialogHeaderEl)、弹窗底部元素(dialogFootEl)和弹窗内容元素(dragDom)。
        const dialogHeaderEl = el.querySelector(".el-popover__title"); // 获取弹窗标题栏元素
        const dragDom = el.querySelector(".search-poppver"); // 获取弹窗内容元素
        const that = vnode.context;

        // 2、判断是否成功获取到弹窗内容元素，若未获取到则不绑定拖拽功能。
        if (!dragDom) return; // 如果没有找到弹窗内容元素，则不绑定拖拽

        // 3、获取弹窗内容元素的样式属性(sty)，包括位置等信息。
        // 获取弹窗内容元素的样式属性
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

        // 4、定义moveDown函数，该函数用于处理鼠标按下事件，实现拖拽效果。
        const moveDown = e => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dialogHeaderEl.offsetLeft;
          const disY = e.clientY - dialogHeaderEl.offsetTop;

          // 获取到的值带px 正则匹配替换
          let styL, styT;

          // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
          if (sty.left.includes("%")) {
            styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, "") / 100);
            styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, "") / 100);
          } else {
            styL = +sty.left.replace(/\px/g, "");
            styT = +sty.top.replace(/\px/g, "");
          }

          // 5、在document.onmousemove事件处理函数中计算当前元素的移动距离，并限制移动范围在窗口可视区域内。
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            let l = e.clientX - disX + styL;
            let t = e.clientY - disY + styT;

            // 设置边界限制
            let minL = -((document.documentElement.clientWidth - dragDom.offsetWidth) * 0.5 + dragDom.offsetWidth - 50);
            let maxL = (document.documentElement.clientWidth - dragDom.offsetWidth) * 0.5 + dragDom.offsetWidth - 50;
            let minT = -document.documentElement.clientHeight * 0.1;
            let maxT = document.documentElement.clientHeight - dragDom.offsetHeight - document.documentElement.clientHeight * 0.1 + dragDom.offsetHeight - 50;
            if (l <= minL) {
              l = minL;
            } else if (l >= maxL) {
              l = maxL;
            }
            if (t <= minT) {
              t = minT;
            } else if (t >= maxT) {
              t = maxT;
            }
            t = t > minT ? t : minT;

            // 移动当前元素
            dragDom.style.left = `${l}px`;
            dragDom.style.top = `${t}px`;

            //将此时的位置传出去
            //binding.value({x:e.pageX,y:e.pageY})
          };

          // 6、在document.onmouseup事件处理函数中清除事件监听，结束拖拽操作。
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
        // 7、将moveDown函数绑定到弹窗标题栏元素的onmousedown事件上，当鼠标按下标题栏时触发拖拽。
        dialogHeaderEl.onmousedown = moveDown;
      }
    }
  },
  computed: {
    getChildrenEquipmentList() {
      const equipmentRadio = this.equipmentList.radio;
      let childrenList = {};
      this.equipment.forEach((element, index) => {
        const paramsArr = [];
        if (equipmentRadio == element.name) {
          childrenList.children = element.children;
          for (let i in element.params) {
            const obj = {};
            obj.value = i;
            obj.name = element.params[i];
            paramsArr.push(obj);
          }
          childrenList.params = paramsArr;
        }
      });
      return childrenList;
    },
    iconStyle() {
      return {
        color: this.blinkInterval ? "red" : this.normalColor
      };
    }
  },
  methods: {
    //设置光伏板最初的颜色值，方便恢复时使用
    setOringColorGFBAndOther() {
      this.gltf.scene.traverse(obj => {
        if (obj.name.includes("sb-gfb")) {
          this.oringColor = obj.material.color.clone();
        } else if (obj.name.indexOf("sb-") > -1) {
          this.colorClone = obj.material.color.clone();
        }
      });
    },
    checkAndSetOringColorGFBAndOther() {
      if (this.gltf) {
        this.setOringColorGFBAndOther();
      } else {
        setTimeout(() => {
          console.log("模型2秒后未渲染成功，将继续等待两秒");
          this.checkAndSetOringColorGFBAndOther();
        }, 2000);
      }
    },
    startBlinking() {
      console.log("进入了startBlinking");
      if (this.blinkInterval) {
        clearInterval(this.blinkInterval);
      }
      this.blinkInterval = setInterval(() => {
        this.isBlinking = !this.isBlinking;
      }, 500); // 每500毫秒切换一次
    },

    stopBlinking() {
      if (this.blinkInterval) {
        clearInterval(this.blinkInterval);
        this.blinkInterval = null;
      }
      this.isBlinking = false;
    },
    clickAlarm() {
      console.log("单击了警报按钮");
      this.isHiddenAlarm = this.isHiddenAlarm === 0 ? 1 : 0;
      this.$emit("clickAlramInfo", this.isHiddenAlarm);

      //this.stopBlinking();
    },

    //单击了变更主题事件
    onclickChangeColor() {
      console.log("单击了变更主题的事件");
      // 在点击按钮时切换 toggleValue 的值
      this.toggleValue = this.toggleValue === 0 ? 1 : 0;
      this.$emit("footChangeColor", this.toggleValue);
    },
    // 获取设备参数列表
    async getdeviceList() {
      const r = await deviceList();
      this.equipment = r.data.equipment;
      this.selectChildName = r.data.equipment[0].name;
      this.$emit("postEquipmentList", r.data.equipment);
    },
    // 点击全选
    handleCheckAllChange(val) {
      let checkAllArr,
        childrenArr = [],
        childrenIdArr = [];
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          element.children.forEach(childrenelement => {
            childrenArr.push(childrenelement.name);
            // childrenIdArr.push(childrenelement.id)
          });

          checkAllArr = childrenArr;
        }
      });
      this.equipmentList.children = val ? checkAllArr : [];
      this.isIndeterminate = false;
      this.equipmentList.childrenId = [];
      console.log(val, this.equipmentList, " 全选参数val");
      this.setMaterialColor(this.equipmentList.children);
    },
    // 子设备多选切换
    childrenEquipmentChange(value) {
      // if(this.equipmentList.radio == "光伏系统"){
      //   this.checkBoxMax = 6;
      // }
      console.log("子设备多选切换", value);
      this.equipmentList.childrenId = [];
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          element.children.forEach(item => {
            for (let i in value) {
              if (value[i] == item.name) {
                this.equipmentList.childrenId.push(item.id);
              }
            }
          });
        }
      });
      let checkedCount = value.length;
      let childrenLen;
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          childrenLen = element.children.length;
        }
      });
      this.equipmentList.checkAll = checkedCount === childrenLen;
      if (checkedCount == childrenLen) {
        this.equipmentList.childrenId = [];
      }
      this.isIndeterminate = checkedCount > 0 && checkedCount < childrenLen;
      console.log(this.equipmentList, "childrenEquipmentChange()->this.equipmentlist");
      this.setMaterialColor(value);
    },
    //设置设备选中的颜色操作
    setMaterialColor(value) {
      this.gltf.scene.traverse(obj => {
        if (obj.name.includes("sb-gfb")) {
          //点击设备变色前，先全部恢复为原色后再上色

          console.log("设置颜色sb-gfb");
          obj.material.color.set(this.oringColor);
        } else if (obj.name.includes("sb")) {
          //点击设备变色前，先全部恢复为原色后再上色

          console.log("设置颜色sb- ");
          obj.material.color.set(this.colorClone);
          return;
        }
      });
      if (value) {
        this.changeColor(value);
      }
    },
    changeColor(value) {
      console.log(value, "changeColor () value");
      for (let i in value) {
        this.gltf.scene.traverse(function (obj) {
          if (obj.deviceName == value[i]) {
            obj.material.color.set(0x00ffff);
          }
        });
      }
    },
    // 设备单选切换
    equipmentChoose() {
      console.log("设备单选切换");
      this.$refs.childPopover.doClose();
      this.equipmentList.checkAll = false;
      this.equipmentList.children = [];
      this.equipmentList.params = "";
      this.equipmentList.childrenId = [];
      this.selectChildName = this.equipmentList.radio;
      const that = this;
      // this.gltf.scene.traverse(function (obj) {
      //   that.getChooseFromMapArr.forEach((element) => {
      //     if (obj.deviceName == element) {
      //       obj.material.color.set(that.colorClone);
      //     }
      //   });
      // });
      this.setMaterialColor();
    },
    // 参数单选切换
    paramsChoose(value) {
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          for (let i in element.params) {
            if (value == element.params[i]) {
              this.equipmentList.paramsCode = i;
            }
          }
        }
      });
    },
    //关闭搜索弹出框
    closePoppver() {
      this.searchPopoverIsVisible = !this.searchPopoverIsVisible;
      // console.log(this.selectFlag,"this.selectFlag")
      if (this.selectFlag) {
        const that = this;
        this.$parent.removeCompareMeshs();
        this.$parent.removeTag();
        this.gltf.scene.traverse(function (obj) {
          that.selectArr.forEach(element => {
            if (obj.deviceName == element) {
              obj.material.color.set(that.colorClone);
            }
          });
        });
        this.selectFlag = false;
      }
    },
    // 筛选确定按钮
    filterChildEquipmentEvent() {
      this.selectArr = [];
      this.$refs.childPopover.doClose();
      this.$emit("isMapStatus", "select");
      this.selectFlag = true;
      const params = {};
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          params.deviceType = element.code;
        }
      });
      params.param = this.form.params;
      params.paramType = this.form.type;
      params.paramValue = this.form.value;
      filterChildEquipment(params).then(res => {
        // console.log(res,"res.data")
        if (res.data.length != 0) {
          const arr = [],
            arrId = [];
          res.data.forEach(element => {
            this.selectArr.push(element.name);
            arr.push(element.name);
            arrId.push(element.deviceId);
          });
          this.$set(this.equipmentList, "children", arr);
          this.$set(this.equipmentList, "childrenId", arrId);
          let checkedCount = arr.length;
          let childrenLen;
          this.equipment.forEach(element => {
            if (this.equipmentList.radio == element.name) {
              childrenLen = element.children.length;
            }
          });
          let isAll = false;
          checkedCount == childrenLen ? isAll = true : isAll = false;
          this.$set(this.equipmentList, "checkAll", isAll);
          const that = this;
          this.gltf.scene.traverse(function (obj) {
            if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
              obj.material.transparent = true;
              obj.material.opacity = 0.2;
            }
            if (obj.name.indexOf("sb-") > -1) {
              obj.material.color.set(that.colorClone);
            }
            for (let i in arr) {
              if (obj.deviceName == arr[i]) {
                obj.material.color.set(0x00ffff);
              }
            }
          });
        } else {
          this.$set(this.equipmentList, "children", []);
          this.$set(this.equipmentList, "checkAll", false);
        }
      }).catch();
    },
    // 筛选功能 参数下拉框和搜索弹窗中的参数单选双向绑定
    filterParamsChange(value) {
      let paramsStr = "";
      this.getChildrenEquipmentList.params.forEach(element => {
        if (value == element.value) {
          paramsStr = element.name;
        }
      });
      this.$set(this.equipmentList, "params", paramsStr);
    },
    processSession() {
      if (this.equipmentList.checkAll == true && this.equipmentList.chartTimeRange != null && this.equipmentList.chartTimeRange.length > 0) {
        //全选，选择了时间的情况
        if (this.equipmentList.chartTimeRange[1].getTime() - this.equipmentList.chartTimeRange[0].getTime() > 24 * 60 * 60 * 1000) {
          //大于一天
          this.configSaveSessionInfo("moreOneday");
        } else {
          // 小于一天
          this.configSaveSessionInfo("lessOneday");
        }
      } else if (this.equipmentList.checkAll == true && (this.equipmentList.chartTimeRange == null || this.equipmentList.chartTimeRange.length <= 0)) {
        //全选且没有选时间
        this.configSaveSessionInfo("");
      }
    },
    //配置保存sessionInfo的参数
    configSaveSessionInfo(param) {
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "平均COP") {
        sessionStorage.setItem(`${param}gaugeCharts`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~以下是~~pieCharts2~环形图~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "余热回收系统" && this.equipmentList.params == "余热回收效率") {
        sessionStorage.setItem(`${param}Yure`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "绿电占比") {
        sessionStorage.setItem(`${param}Kongqi`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "绿电占比") {
        sessionStorage.setItem(`${param}Gaowen`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~以下是~~barCharts2~柱状图~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~~~~RZhengqiL~~~~~~~~~~~~~~~~~~~~~~~~~1~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "燃气锅炉" && this.equipmentList.params == "蒸汽量") {
        sessionStorage.setItem(`${param}RZhengqiL`, JSON.stringify(this.equipmentList));
      }

      //~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~ZZhengqiRL~~~~~~~~~~~~~~~~~~~~~~~~2~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "蒸锅" && this.equipmentList.params == "蒸汽热量") {
        sessionStorage.setItem(`${param}ZZhengqiRL`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~~~~YHuishou~~~~~~~~~~~~~~~~~~~~~~~~~3~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "余热回收系统" && this.equipmentList.params == "回收余热量") {
        sessionStorage.setItem(`${param}YHuishou`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~GZhire~~~~~~~~~~~~~~~~~~~~~~~~~~~~~4~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "制热量") {
        sessionStorage.setItem(`${param}GZhire`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~~~GHaodian~~~~~~~~~~~~~~~~~~~~5~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "耗电量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem(`${param}GHaodian`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~KZhire~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "制热量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem(`${param}KZhire`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~KHaodian~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "耗电量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem(`${param}KHaodian`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~KAverageCOP~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "平均COP" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem(`${param}KAverageCOP`, JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~lineData~~~~~Fadianl~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "光伏系统" && this.equipmentList.params == "发电量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem(`${param}Fadianl`, JSON.stringify(this.equipmentList));
      }
    },
    //
    processAllCHeckedSaveSessionInfo() {
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "平均COP" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("gaugeCharts", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~以下是~~pieCharts2~环形图~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "余热回收系统" && this.equipmentList.params == "余热回收效率" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("Yure", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "绿电占比" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("Kongqi", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "绿电占比" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("Gaowen", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~以下是~~barCharts2~柱状图~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~~~~RZhengqiL~~~~~~~~~~~~~~~~~~~~~~~~~1~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "燃气锅炉" && this.equipmentList.params == "蒸汽量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("RZhengqiL", JSON.stringify(this.equipmentList));
      }

      //~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~ZZhengqiRL~~~~~~~~~~~~~~~~~~~~~~~~2~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "蒸锅" && this.equipmentList.params == "蒸汽热量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("ZZhengqiRL", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~~~~YHuishou~~~~~~~~~~~~~~~~~~~~~~~~~3~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "余热回收系统" && this.equipmentList.params == "回收余热量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("YHuishou", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~GZhire~~~~~~~~~~~~~~~~~~~~~~~~~~~~~4~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "制热量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("GZhire", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~~~GHaodian~~~~~~~~~~~~~~~~~~~~5~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "高温水源热泵机房" && this.equipmentList.params == "耗电量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("GHaodian", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~KZhire~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "制热量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("KZhire", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~~KHaodian~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "耗电量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("KHaodian", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~~~KAverageCOP~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "空气源热泵" && this.equipmentList.params == "平均COP" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("KAverageCOP", JSON.stringify(this.equipmentList));
      }
      //~~~~~~~~~~~~~~~~~~~~~~~lineData~~~~~Fadianl~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.radio == "光伏系统" && this.equipmentList.params == "发电量" && this.equipmentList.checkAll == true) {
        sessionStorage.setItem("Fadianl", JSON.stringify(this.equipmentList));
      }

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (this.equipmentList.checkAll == true && this.equipmentList.chartTimeRange != null && this.equipmentList.chartTimeRange.length > 0) {
        //大于一天
        if (this.equipmentList.chartTimeRange[1].getTime() - this.equipmentList.chartTimeRange[0].getTime() > 24 * 60 * 60 * 1000) {
          sessionStorage.setItem("CommonMoreOneday", JSON.stringify(this.equipmentList));
        } else {
          // //小于一天
          sessionStorage.setItem("CommonLessOneday", JSON.stringify(this.equipmentList));
        }
      }

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~lineCharts~~~~小于一天~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    },

    // 点击搜索按钮
    search(type) {
      console.log(this.equipmentList, "   search(type):equipmentList");
      // this.$emit('isMapStatus', false)
      // this.$store.commit("search/SEARCHDATA", this.searchList);
      this.equipmentList.type = type;
      console.log(this.equipmentList, "::::::::");
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          this.equipmentList.code = element.code;
        }
      });
      //this.processAllCHeckedSaveSessionInfo();
      this.processSession();
      // if (
      //   this.equipmentList.radio == "光伏系统" &&
      //   this.equipmentList.params == "发电量" &&
      //   this.equipmentList.checkAll == true
      // ) {
      //   sessionStorage.setItem("lineData", JSON.stringify(this.equipmentList));
      // }
      this.getearchData(this.equipmentList);
      if (type == "reset") {
        this.equipmentList.radio = "燃气锅炉";
        this.equipmentList.children = [];
        this.equipmentList.params = "";
        this.equipmentList.tune = [];
        this.equipmentList.chartTimeRange = [];
        this.equipmentList.checkAll = false;
        const childEquipmentDiv = document.querySelectorAll(".cloneDiv");
        childEquipmentDiv.forEach(element => {
          element.remove();
        });
        this.$emit("isMapStatus", undefined);
        this.$parent.removeTag();
        const that = this;
        this.gltf.scene.traverse(function (obj) {
          if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
            obj.material.transparent = false;
            obj.material.opacity = 1;
          }
          that.getChooseFromMapArr.forEach(element => {
            if (obj.deviceName == element) {
              obj.material.color.set(that.colorClone);
            }
          });
        });
      }
      if (type == "timeline") {
        if (this.equipmentList.chartTimeRange.length <= 0 || this.equipmentList.chartTimeRange == null || this.equipmentList.children.length == 0) {
          this.searchPopoverIsVisible = true;
        } else {
          this.searchPopoverIsVisible = false;
        }
      }
    },
    // 图表初始化
    charts(series, xAxisArr) {
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.setOption({
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "13%",
          bottom: "17%",
          left: "13%",
          right: "5%"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 1
            }
          },
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#c3d2dd",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,124,226,0.8)",
              type: "dotted"
            }
          }
        },
        series: series
      }, true);
    },
    //从地图选择
    chooseFromMap() {
      this.$refs.popoverRef.doClose();
      this.mapBtn = true;
      this.isDisabled = true;
      this.$emit("isMapStatus", this.isDisabled);
      this.getearchData(this.equipmentList);
      this.$parent.removeCompareMeshs();
      this.$parent.removeTag();
      this.$emit("isChooseFromMapFlag", true);
      this.equipmentList.children = [];
      this.equipmentList.childrenId = [];
      const that = this;
      this.gltf.scene.traverse(function (obj) {
        if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
          obj.material.transparent = true;
          obj.material.opacity = 0.2;
        }
        if (obj.name.includes("sb-gfb")) {
          //  console.log("set  sb-gfb", that.oringColor);
          that.oringColor = obj.material.color.clone();
        }
        if (obj.name.indexOf("sb-") > -1) {
          that.colorClone = obj.material.color.clone();
        }
      });
      this.resetMapEquipment();
    },
    // 从地图选择 确定事件
    chooseMapEquipment() {
      this.mapBtn = false;
      this.isDisabled = false;
      this.$emit("isMapStatus", this.isDisabled);
      this.$parent.clearArr();
      this.searchPopoverIsVisible = true;
      // const that = this;
      console.log("单击了确定按钮");
      // this.gltf.scene.traverse(function (obj) {
      //   if (obj.name.indexOf("sb-") > -1) {
      //     obj.material.color.set(that.colorClone);
      //   }
      // });
      this.restoreMash();
    },
    restoreMash() {
      //通知主组件恢复
      this.$emit("setRestoreMesh");
    },
    // 从地图选择 重置事件
    resetMapEquipment() {
      this.mapBtn = true;
      this.$parent.removeCompareMeshs();
      this.getChooseFromMapArr = [];
      this.getChooseFromMapIdArr = [];
      this.$parent.clearArr();
      const that = this;
      this.gltf.scene.traverse(function (obj) {
        if (obj.name.indexOf("sb-") > -1) {
          obj.material.color.set(that.colorClone);
        }
      });
    },
    //筛选子设备
    selectChildEquipment() {
      this.form.params = "";
      this.form.type = "";
      this.form.value = "";
      this.equipment.forEach(element => {
        if (this.equipmentList.radio == element.name) {
          this.filterParams = element.params;
        }
      });
      this.gltf.scene.traverse(function (obj) {
        if (obj.name.indexOf("sb-") > -1) {
          this.colorClone = obj.material.color.clone();
        }
      });
    },
    // 关闭筛选弹窗
    closeSelectChildPop() {
      this.$refs.childPopover.doClose();
    },
    // 管道开关按钮
    tuneFn() {
      this.tuneIsOpen = !this.tuneIsOpen;
      if (this.tuneIsOpen) {
        this.tuneIsOpenText = "开启";
        this.$parent.start();
      } else {
        this.tuneIsOpenText = "关闭";
        this.$parent.stopObjMove();
      }
    },
    showMicrophone() {
      sessionStorage.removeItem("voice");
      clearInterval(this.microphoneTimer);
      let that = this;
      this.microphoneTimer = setInterval(function () {
        let voiceWord = sessionStorage.getItem("voice");
        console.log(voiceWord, "voiceWord");
        if (voiceWord != null) {
          clearInterval(that.microphoneTimer);
          let params = {
            word: voiceWord
          };
          if (voiceWord.indexOf("设备") > -1) {
            voiceToWord(params).then(res => {
              if (res.data.sonDevice.length == 0) {
                that.$message.error("暂无数据");
                that.$refs.microphonePoppver.showPopper = false;
                clearInterval(that.microphoneTimer);
              } else {
                clearInterval(that.microphoneTimer);
                that.$refs.microphonePoppver.showPopper = false;
                that.$refs.popoverRef.showPopper = true;

                // 清空已有选项
                that.equipmentList.radio = "燃气锅炉";
                that.equipmentList.children = [];
                that.equipmentList.childrenId = [];
                that.equipmentList.params = "";
                that.equipmentList.checkAll = false;

                // 重新赋值筛选出的信息
                let deviceName = res.data.sonDevice[0].name.match(/[\u4e00-\u9fa5]+/g).join("");
                that.equipmentList.radio = deviceName;
                const sonDevices = res.data.sonDevice;
                const sonDeviceArr = sonDevices.map(element => element.name);
                const sonDeviceArrId = sonDevices.map(element => element.deviceId);
                that.equipmentList.children = sonDeviceArr;
                that.equipmentList.childrenId = sonDeviceArrId;
                let checkedCount = sonDeviceArr.length;
                let childrenLen;
                that.equipment.forEach(element => {
                  if (that.equipmentList.radio == element.name) {
                    childrenLen = element.children.length;
                  }
                });
                let isAll = false;
                checkedCount == childrenLen ? isAll = true : isAll = false;
                that.$set(that.equipmentList, "checkAll", isAll);
                //更新多选按钮的数据
                that.childrenEquipmentChange(sonDeviceArr);
              }
            }).catch();
          }
          if (voiceWord.indexOf("数据") > -1) {
            voiceToChart(params).then(res => {
              that.$refs.microphonePoppver.showPopper = false;
              let sonDeviceArr = [],
                voiceChartTimeRange = [];
              res.data.sonDevice.forEach(element => {
                sonDeviceArr.push(element.name);
              });
              if (res.data.createdStartTime == "" || res.data.createdEndTime == "") {
                voiceChartTimeRange = [];
              } else {
                //将 时间戳（单位为秒）转换为 JavaScript Date 对象
                voiceChartTimeRange = [new Date(Number(res.data.createdStartTime + "000")), new Date(Number(res.data.createdEndTime + "000"))];
              }
              that.equipmentList = {
                radio: res.data.deviceTypeName,
                children: sonDeviceArr,
                params: res.data.paramName,
                tune: [],
                type: "search",
                chartTimeRange: voiceChartTimeRange,
                checkAll: true,
                childrenId: [],
                paramsCode: res.data.paramCode
              };
              that.search("search");
            }).catch();
          }
        }
      }, 1000);
    },
    controlDevice() {
      console.log("单机了模拟控制按钮");
      this.$emit("clickSimulateControl", null);
    },
    onClickDataExport() {
      console.log("单击了数据导出按钮");
      this.$emit("clickExportTable");

      // const doc = new jsPDF();
      // const jsonData = JSON.parse(this.jsonData);

      // // 生成表格数据
      // // 生成表格数据
      // const columns = Object.keys(jsonData[0]).map((key) => key);
      // const rows = jsonData.map((item) => columns.map((col) => item[col]));
      // console.log(columns, "columns");

      // console.log(rows, "rows");
      // // 使用autotable生成表格
      // doc.autoTable({
      //   head: [columns],
      //   body: rows,
      // });

      // // 保存PDF文件
      // doc.save("table.pdf");
    }
  },

  watch: {
    chooseFromMapArr: {
      handler(oldData, newData) {
        // console.log(newData,"NNNNNNNNNNNNNNNNNNNNNNNN")
        const uniqueArr = Array.from(new Set(newData));
        let arr = [];
        uniqueArr.forEach(element => {
          if (element.indexOf(this.equipmentList.radio) > -1) {
            arr.push(element);
          }
        });
        this.$set(this.equipmentList, "children", arr);
        let checkedCount = arr.length;
        let childrenLen;
        this.equipment.forEach(element => {
          if (this.equipmentList.radio == element.name) {
            childrenLen = element.children.length;
          }
        });
        let isAll = false;
        checkedCount == childrenLen ? isAll = true : isAll = false;
        this.$set(this.equipmentList, "checkAll", isAll);
      }
    },
    chooseFromMapIdArr: {
      handler(oldData, newData) {
        // console.log(newData,"mmmmmmmmmmmmmmmmmmmmmm")
        const uniqueArr = Array.from(new Set(newData));
        this.$set(this.equipmentList, "childrenId", uniqueArr);
        let checkedCount = uniqueArr.length;
        let childrenLen;
        this.equipment.forEach(element => {
          if (this.equipmentList.radio == element.name) {
            childrenLen = element.children.length;
          }
        });
        let isAll = false;
        checkedCount == childrenLen ? isAll = true : isAll = false;
        this.$set(this.equipmentList, "checkAll", isAll);
      }
    },
    isDisabled() {
      console.log("isDisabled发生变化了");
    }
  },
  beforeDestroy() {
    this.stopBlinking();
  }
};